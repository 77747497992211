import GitHubLanding from "./git-hub-landing";
import GitHubMiddle from "./git-hub-middle";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import GitHubVideoSection from "./git-hub-middle";

const scrollTo = (id: string) => {
  const targetDiv = document.getElementById(id);
  if (targetDiv) {
    const container = document.querySelector(".product-list-container");
    if (container) {
      container.scrollTo({
        left: targetDiv.offsetLeft,
        behavior: "smooth",
      });
    }
  }
};

function GitHub() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <motion.div
      className="App"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <motion.img
        src="https://macefoods.com/wp-content/uploads/2023/05/scroll-down.gif"
        alt="scroll down"
        className="scroll-down"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0, y: 100 }}
        transition={{
          duration: 0.2,
          delay: 2,
        }}
      />
      <div className="git-hub-page-container">
        <div className="scroll-section" id="top">
          <GitHubLanding />
        </div>
        <div className="scroll-section" ref={ref}>
          <GitHubVideoSection />
          <motion.div
            initial={{ y: 200, opacity: 0 }}
            animate={inView ? { y: 0, opacity: 1 } : { y: 0 }}
            transition={{ delay: 0.8, duration: 1, type: "tween" }}
          >
            <a href="#top" className="to-top" onClick={() => scrollTo("top")}>
              TO TOP
            </a>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
}

export default GitHub;
