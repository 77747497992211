import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import "./design.css";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

function DesignBottom() {
  const controls = useAnimation();

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <div className="white-container">
      <div className="design-bottom-content" ref={ref}>
        <p
          style={{
            textAlign: "start",
          }}
        >
          I learnt <em>Design</em> and built my repertoire on the job. From a
          &nbsp; <em>Photography</em> beginning, I’ve spent years building
          brands, developing my <em>Design Skill</em> and an extensive knowledge
          of &nbsp; <em>Design Softwares</em>, in particular Adobe systems.{" "}
        </p>
        <div>
          <p style={{ textAlign: "end" }}>
            I get engrossed when translating <em>design</em> into <em>web</em>{" "}
            and find myself obsessing over the <em>finer details</em>.
          </p>
        </div>
      </div>
    </div>
  );
}

export default DesignBottom;
