import "./frontend.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

function FrontendAbout() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <div className="white-container">
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 4 }}
        className="frontend-bottom-content"
      >
        <p style={{ marginTop: "-50px", textAlign: "start" }}>
          My aim is to craft exceptional user experiences for <em>beautiful</em>
          &nbsp; applications. I'm passionate about <em>minimalism</em>, clean
          &nbsp; <em>UIs</em>, and <em>striking</em>&nbsp; fonts.{" "}
        </p>
        <p style={{ textAlign: "end" }}>
          My focus is on delivering <em>seamless</em> functionality to create
          &nbsp; <em>Enticing</em> user journeys.
        </p>
      </motion.div>
    </div>
  );
}

export default FrontendAbout;
