import "./frontend.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";

function FrontendAbout3() {
  // import Motion Framer useAnimation hook
  // import inView from react-intersection-observer to ref view point - returns boolean true if in view, false if not.
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    // if statement to set use object visible when useInView hoot return true
    if (inView) {
      controls.start("visible");
    }
    // dependencies
  }, [controls, inView]);

  return (
    <div id="frontend-about-3" className="white-container">
      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 4 }}
        className="frontend-bottom-content"
      >
        <p style={{ marginTop: "-50px", textAlign: "start" }}>
          I'm always eager to learn new frameworks and <em>languages</em> to
          expand my skillset and help me tackle new <em>challenges</em>.
        </p>
      </motion.div>
    </div>
  );
}

export default FrontendAbout3;
