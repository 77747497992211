import "./about-me.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import ImageCycle from "../hooks/animated-routes/image-cycle";
import CssIcon from "./assets/css-icon.png";
import JavascriptIcon from "./assets/javascript-icon.png";
import TypescriptIcon from "./assets/typescript-icon.png";
import ReactIcon from "./assets/react-logo_.png";
import TailwindIcon from "./assets/tailwind-icon.png";

const images = [
  CssIcon,
  JavascriptIcon,
  TypescriptIcon,
  ReactIcon,
  TailwindIcon,
];

function AboutMeMiddle() {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: false, // Ensure the animation triggers each time the element comes into view
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div className="clipped-text clipped-white-container" ref={ref}>
      <div className="title-container">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1 }}
        >
          CSS
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          REACT
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1, delay: 0.4 }}
        >
          TAILWIND
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          TYPESCRIPT
        </motion.h1>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={inView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ duration: 1, delay: 0.8 }}
          whileHover={{
            transition: { duration: 0.5 },
          }}
        >
          JAVASCRIPT
        </motion.h1>
      </div>
      <motion.div
        style={{ width: "50px", height: "50px", zIndex: 99 }}
        className="icon-cycle-about-me"
        initial={{ opacity: 0 }}
        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: "-200" }}
        transition={{ duration: 2, delay: 1.5 }}
      >
        <ImageCycle images={images} />
      </motion.div>
    </motion.div>
  );
}

export default AboutMeMiddle;
