import "./frontend.css";
import { motion } from "framer-motion";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
import ReactLogo from "./Assets/react-logo.png";

function FrontendHeader() {
  return (
    <>
      <div id="frontend-landing" className="frontend-landing-container">
        <div className="frontend-title-container">
          <div className="title-top-line front">
            <motion.h1
              initial={{ y: "100vh", x: -20, opacity: 0 }}
              animate={{ y: 0, x: 0, opacity: 1 }}
              transition={{ duration: 1, type: "tween" }}
              style={{ textAlign: "start" }}
              className="title-left-frontend"
            >
              FRONT
            </motion.h1>
            <motion.img
              src={ReactLogo}
              alt="React Icon"
              className="react-icon"
              whileHover={{ rotate: 1080 }}
              animate={{ rotate: 360 }}
              transition={{
                loop: Infinity,
                duration: 10,
              }}
            />
          </div>
          <motion.h1
            initial={{ y: "100vh", x: -24, opacity: 0 }}
            animate={{ y: 0, x: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 1, type: "tween" }}
            className="title-left-frontend end"
            style={{ textAlign: "start", marginTop: "20px" }}
          >
            <em>End</em>
          </motion.h1>
        </div>
      </div>
    </>
  );
}

export default FrontendHeader;
