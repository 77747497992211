import { motion } from "framer-motion";
import WwwIcon from "./assets/www-icon.png";
import "./about-me.css";

function AboutMeLanding() {
  return (
    <div className="about-me-landing-container">
      <motion.div
        className="creative"
        initial={{ y: "-100vh", x: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1, x: 0 }}
        transition={{ delay: 1, duration: 0.6, type: "tween" }}
      >
        <b>Creative</b>
        <motion.img
          src={WwwIcon}
          className="www-icon"
          style={{
            height: "50px",
            width: "50px",
          }}
          alt="www-icon"
          whileHover={{ rotate: 2160 }}
          initial={{ opacity: 0, x: "-150" }}
          animate={{ y: 0, opacity: 1, x: 0, rotate: 2160 }}
          transition={{ duration: 2, loop: Infinity }}
        />
      </motion.div>

      <motion.h1
        initial={{ y: "-100vh", x: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1, x: 0 }}
        transition={{ delay: 1, duration: 0.4, type: "tween" }}
      >
        FRONTEND
      </motion.h1>
      <motion.h1
        initial={{ y: "-100vh", x: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1, x: 0 }}
        transition={{ delay: 1, duration: 0.2, type: "tween" }}
      >
        DEVELOPER
      </motion.h1>
    </div>
  );
}

export default AboutMeLanding;
